import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import { UserCircle as UserCircleIcon } from '../../shared/Icons/user-circle';
import { Cog as CogIcon } from '../../shared/Icons/cog';
import LogoutIcon from '@mui/icons-material/Logout';
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from '../../shared/Icons/switch-horizontal-outlined';
import { useAuth } from '../../../helpers/hooks/useAuth';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { logout, user } = useAuth();

  const handleLogout = async () => {
    try {
      logout();
      onClose?.();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}>
        <Avatar
          src={user.avatar}
          sx={{
            height: 40,
            width: 40
          }}>
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1
          }}>
          <Typography variant="body1">{user.name}</Typography>
          <Typography color="textSecondary" variant="body2">
            {user.firstname} {user.lastname}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <MenuItem component="a">
          <ListItemIcon>
            <UserCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Profile</Typography>} />
        </MenuItem>
        <MenuItem component="a">
          <ListItemIcon>
            <CogIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Settings</Typography>} />
        </MenuItem>
        <MenuItem component="a">
          <ListItemIcon>
            <SwitchHorizontalOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Change organization</Typography>} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
