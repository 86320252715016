import { Card, Divider } from '@mui/material';
import React from 'react';
import { PropertyList } from '../../identities/PropertyList';
import { PropertyListItem } from '../../identities/PropertyListItem';
import PropTypes from 'prop-types';

const DocumentDetailsList = ({ document }) => {
  const labels = [
    { id: 'documentType', label: 'Document Type' },
    { id: 'documentNumber', label: 'Document Number' },
    { id: 'issuingCountry', label: 'Issuing Country' },
    /*{ id: 'expiryDate', label: 'Expiry Date' },*/
    { id: 'score', label: 'Score' },
    { id: 'issuingAuthority', label: 'Issuing Authority' }
  ];
  return (
    <Card sx={{ marginTop: '15px' }}>
      <Divider />
      <PropertyList>
        {labels.map((label) => (
          <PropertyListItem
            key={label.id}
            align="horizontal"
            divider
            label={label.label}
            value={document[label.id]}
          />
        ))}
      </PropertyList>
    </Card>
  );
};

export default DocumentDetailsList;

DocumentDetailsList.propTypes = {
  document: PropTypes.object
};
