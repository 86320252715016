import React from 'react';
import { Avatar, Box, Chip, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const IdenityKey = ({ client, identityKey, displayPortrait }) => {
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid
        item
        sx={{
          alignItems: 'center',
          display: 'flex',
          overflow: 'hidden'
        }}>
        <Avatar
          src={client.portrait}
          sx={{
            height: 64,
            mr: 2,
            width: 64,
            display: displayPortrait ? 'none' : 'block'
          }}>
          {client.name}
        </Avatar>
        <div>
          <Typography variant="h5">{client.name}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <Typography variant="subtitle2" fontSize="smaller">
              Identity key:
            </Typography>
            <Chip label={identityKey} size="small" sx={{ ml: 1 }} />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default IdenityKey;

IdenityKey.propTypes = {
  client: PropTypes.object.isRequired,
  displayPortrait: PropTypes.bool,
  identityKey: PropTypes.string.isRequired
};
