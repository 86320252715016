import { Card, CardHeader, Divider } from '@mui/material';
import { PropertyList } from '../PropertyList';
import { PropertyListItem } from '../PropertyListItem';
import PropTypes from 'prop-types';
import { dateFormat } from '../../../helpers/utils/dateFormat';

const ClientDetails = ({ client }) => {
  const labels = {
    email: 'Email',
    mobileNumber: 'Tél.',
    country: 'Pays',
    birthDate: 'Date de naissance',
    address: 'Adresse',
    nationality: 'Nationalité',
    zipCode: 'Code postale'
  };

  client.birthDate = dateFormat(client.birthDate);

  return (
    <div>
      <Card>
        <CardHeader title="Informations :" />
        <Divider />
        <PropertyList>
          {Object.keys(labels).map((key) => {
            return (
              <PropertyListItem
                align="horizontal"
                divider
                key={key}
                label={labels[key]}
                value={client[key]}
              />
            );
          })}
        </PropertyList>
      </Card>
    </div>
  );
};

ClientDetails.propTypes = {
  client: PropTypes.object
};

export default ClientDetails;
