import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/useAuth';
import Spinner from '../../components/shared/Spinner';

const AuthGuard = (props) => {
  const { children } = props;
  const { initialized } = useAuth();

  if (!initialized) {
    return <Spinner />;
  }

  return <>{children}</>;
};

export default AuthGuard;

AuthGuard.propTypes = {
  children: PropTypes.node
};
