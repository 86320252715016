import Navbar from '../../components/dashboard/Navbar';
import Sidebar from '../../components/dashboard/Sidebar';
import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../../pages/Home';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import AccountContainer from '../AccountContainer';
import IdentitiesPage from '../../pages/Indentities';
import IdentityDetailsPage from '../../pages/IdentityDetails';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  backgroundColor: theme.palette.background.default,
  paddingTop: 64,
  height: '89vh',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

const DashboardContainer = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      <Navbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <Sidebar onClose={() => setIsSidebarOpen(false)} open={isSidebarOpen} />

      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/account" element={<AccountContainer />} />
            <Route path="/identities/:id" element={<IdentityDetailsPage />} exact />
            <Route path="/identities" element={<IdentitiesPage />} />
          </Routes>
        </Box>
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardContainer;
