import { useKeycloak } from '@react-keycloak/web';
import { memo, useCallback, useEffect, useState } from 'react';

export const useAuth = () => {
  const {
    keycloak: { authenticated, login, logout, loadUserProfile, token },
    initialized
  } = useKeycloak();
  const [user, setUser] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const userProfile = await loadUserProfile();
        setUser({
          username: userProfile.username,
          firstname: userProfile.firstName,
          lastname: userProfile.lastName,
          email: userProfile.email
        });
      } catch (error) {
        setError(error);
      }
    };
    fetchUserInfo();
    localStorage.setItem('token', token);
  }, []);

  return {
    isAuthenticated: authenticated,
    error: error,
    token: token,
    initialized: initialized,
    user,
    login: useCallback(() => login()),
    logout: useCallback(() => logout())
  };
};

export default memo(useAuth);
