import PropTypes from 'prop-types';
import { Avatar, TableCell } from '@mui/material';
import InfoWrapper from '../InfoWrapper';
import AssuranceLevel from '../AssuranceLevel';
import Domaine from '../Domaine';
import Status from '../Status';
import Documents from '../Documents';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { dateFormat, timeFormat } from '../../../helpers/utils/dateFormat';

const IdentityRow = ({ identity }) => {
  return (
    <>
      <TableCell align="center">
        <Avatar alt="Remy Sharp" src={`/avatars/avatar-anika_visser.png`} />
      </TableCell>
      <TableCell align="center">
        <InfoWrapper
          values={[
            { value: identity.customerInfo.firstName + ' ' + identity.customerInfo.lastName },
            { value: identity.customerInfo.mobileNumber }
          ]}
        />
      </TableCell>
      <TableCell align="center">
        <InfoWrapper
          values={[
            { value: dateFormat(identity.customerInfo.birthDate) },
            { value: timeFormat(identity.customerInfo.birthDate) }
          ]}
        />
      </TableCell>
      <TableCell align="center">
        <AssuranceLevel value={identity.levelOfAssurance} />
      </TableCell>
      <TableCell align="center">
        <Domaine value={identity.domaine} />
      </TableCell>
      <TableCell align="center">
        <Status value={identity.status} />
      </TableCell>
      <TableCell align="center">
        <Documents value={identity.documentsNumber} />
      </TableCell>
      <TableCell align="center">
        <Link to={`/identities/${identity.identityKey}`}>
          <ArrowForwardIosIcon sx={{ color: 'text.grey', textAlign: 'center' }} />
        </Link>
      </TableCell>
    </>
  );
};

IdentityRow.propTypes = {
  identity: PropTypes.object.isRequired
};

export default IdentityRow;
