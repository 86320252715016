import { Box, Button, Card, CardHeader, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import requestService from '../../../helpers/services/request-service';
import PropTypes from 'prop-types';
import Toast from '../../shared/Toast';
import { STATUS } from '../../../helpers/constants/Status';
import { PLACEHOLDER } from '../../../helpers/constants/placeholder';
import { ALERTS } from '../../../helpers/constants/alerts';

const styles = (theme) => ({
  root: {
    // Match [0, md)
    //       [0, 900px)
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
});
const PortraitValidation = ({ portrait, identityKey }) => {
  const queryClient = useQueryClient();
  const [motif, setMotif] = useState('');
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const setToast = (severity, message) => {
    setMessage(message);
    setSeverity(severity);
  };
  const { mutate } = useMutation(
    (documentStatus) => requestService.patch(`/${identityKey}/portrait`, documentStatus, true),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries(`identity/${identityKey}`);
        setToast('success', ALERTS.PORTRAIT_STATUS_SUCCESS);
      },
      onError: () => {
        setToast('error', ALERTS.PORTRAIT_STATUS_ERROR);
      }
    }
  );
  useEffect(() => {
    if (portrait.motif) {
      setMotif(portrait.motif);
    } else {
      setMotif('');
    }
  }, [portrait.motif]);
  return (
    <>
      <Card>
        <CardHeader title="Portrait :" />
        <Divider />

        <Grid container sx={{ padding: '1rem' }} columns={{ sm: 8, md: 12 }}>
          <Grid item sm={8} md={7}>
            <Box sx={styles}>
              <img
                sx={{ width: '100%', height: '100%', borderRadius: '5px' }}
                src="/avatars/avatar-anika_visser.png"
                alt="Document"
              />
            </Box>
          </Grid>
          <Grid item sm md={5}>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
              <Typography>Status : {portrait.status}</Typography>
            </Box>

            <Box>
              <TextField
                placeholder={PLACEHOLDER.PORTRAIT_MOTIF}
                multiline
                role="textbox"
                rows={5}
                onChange={(e) => setMotif(e.target.value)}
                value={motif}
                sx={{ width: '100%' }}
                maxRows={5}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                  flexWrap: 'wrap'
                }}>
                <Button
                  disabled={motif === ''}
                  role="validate"
                  sx={{
                    width: '100px',
                    backgroundColor: (theme) => theme.palette.stausButton.red,
                    color: '#fff'
                  }}
                  onClick={() => mutate({ status: STATUS.INVALID, motif: motif })}>
                  Invalider
                </Button>

                <Button
                  role="invalidate"
                  sx={{
                    width: '100px',
                    backgroundColor: (theme) => theme.palette.stausButton.green,
                    marginLeft: '10px',
                    color: '#fff'
                  }}
                  onClick={() => mutate({ status: STATUS.VALID })}>
                  Valider
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Toast open={open} severity={severity} message={message} />
    </>
  );
};

PortraitValidation.propTypes = {
  portrait: PropTypes.object.isRequired,
  identityKey: PropTypes.string.isRequired
};
export default PortraitValidation;
