import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const PropertyListItem = (props) => {
  const { align, children, disableGutters, value, label, ...other } = props;

  return (
    <ListItem
      sx={{
        px: disableGutters ? 0 : 3,
        py: 1.5
      }}
      {...other}>
      <ListItemText
        disableTypography
        primary={
          <Typography sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }} variant="subtitle2">
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              flex: 1,
              mt: align === 'vertical' ? 0.5 : 0,
              mx: 30
            }}>
            {children || (
              <Typography color="textSecondary" variant="body2">
                {value ? value : '-'}
              </Typography>
            )}
          </Box>
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0
        }}
      />
    </ListItem>
  );
};

PropertyListItem.defaultProps = {
  align: 'vertical'
};

PropertyListItem.propTypes = {
  align: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};
