import React from 'react';
import { Card, CardHeader, Divider, Grid, Typography, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import { STATUS } from '../../../helpers/constants/Status';

const items = [
  {
    color: '#4CAF50',
    label: STATUS.FINISHED,
    subtitle: 'Excellent',
    value: 20
  },
  {
    color: '#FF9800',
    label: STATUS.PROCESSING,
    subtitle: 'Good condition',
    value: 70
  },
  {
    color: '#F44336',
    label: STATUS.FAILED,
    subtitle: 'Needs attention',
    value: 10
  }
];

const StatisticsTotalIdentities = (props) => {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    fill: {
      opacity: 1
    },
    labels: [''],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            color: theme.palette.text.secondary,
            fontSize: '12px',
            fontWeight: 400,
            offsetY: 20
          },
          value: {
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600,
            offsetY: -20
          }
        },
        hollow: {
          size: '60%'
        },
        track: {
          background: theme.palette.background.default
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  return (
    <Card {...props}>
      <CardHeader title="Statut des identités" />
      <Divider />
      <Grid container spacing={3} sx={{ p: 3 }}>
        {items.map((item) => (
          <Grid item key={item.label} md={4} xs={12}>
            <Card
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
              variant="outlined">
              <Typography sx={{ color: item.color }} variant="h6">
                {item.label}
              </Typography>
              <Chart
                height={200}
                options={{
                  ...chartOptions,
                  colors: [item.color]
                }}
                series={[item.value]}
                type="radialBar"
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default StatisticsTotalIdentities;
