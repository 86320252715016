import { Button, TableCell, Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ImageDocument from '../ImageDocument';
import EditIcon from '@mui/icons-material/Edit';
import { dateFormat } from '../../../helpers/utils/dateFormat';

import { useState } from 'react';
import DocumentDetailsModal from '../../documentDetails/DocumentDetailsModal';

const DocumentRow = ({ document, identityKey, setToast }) => {
  const [open, setOpen] = useState(false);
  const [motif, setMotif] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <TableCell align="center">
        <Tooltip title={document.evidenceId}>
          <span>{document.evidenceId ? document.evidenceId.substring(0, 5) : ''}...</span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">{document.documentType}</TableCell>
      <TableCell align="center">{dateFormat(document.submitDateTime)}</TableCell>
      <TableCell align="center">{document.documentNumber}</TableCell>
      <TableCell align="center">{document.issuingCountry}</TableCell>
      <TableCell align="center">{dateFormat(document.expiryDate)}</TableCell>
      <TableCell align="center">{document.score}</TableCell>
      <TableCell align="center">{document.status}</TableCell>
      <TableCell align="center">
        <ImageDocument
          front={
            document.front ||
            'https://upload.wikimedia.org/wikipedia/commons/0/06/Slovenian_ID_Card_2022_-_Front.jpg'
          }
          back={
            document.back ||
            'https://upload.wikimedia.org/wikipedia/commons/3/38/Bulgarian_identity_card_back.png'
          }
        />
      </TableCell>
      <TableCell align="center">
        <Button onClick={handleOpen}>
          <EditIcon />
        </Button>
      </TableCell>
      <DocumentDetailsModal
        motif={motif}
        setToast={setToast}
        document={document}
        identityKey={identityKey}
        open={open}
        setMotif={setMotif}
        handleClose={handleClose}
      />
    </>
  );
};

DocumentRow.propTypes = {
  document: PropTypes.object,
  identityKey: PropTypes.string,
  setToast: PropTypes.func
};

export default DocumentRow;
