import Keycloak from 'keycloak-js';
import { config } from '../constants/Constants';

const keycloak = new Keycloak({
  url: `${config.url.KEYCLOAK_BASE_URL}`,
  realm: `${config.realm}`,
  clientId: `${config.clientId}`
});

export default keycloak;
