import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { STATUS } from '../../../helpers/constants/Status';

const Container = styled('div')(({ status, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor:
    status === STATUS.FINISHED
      ? theme.palette.statusBackground.finished
      : status === STATUS.PROCESSING
      ? theme.palette.statusBackground.processing
      : theme.palette.statusBackground.failed,
  fontWeight: '500',
  padding: '0.5rem',
  color:
    status === STATUS.FINISHED
      ? theme.palette.statusText.finished
      : status === STATUS.PROCESSING
      ? theme.palette.statusText.processing
      : theme.palette.statusText.failed,
  borderRadius: '100px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  maxWidth: '6rem',
  margin: 'auto'
}));
const Status = (props) => {
  const { value } = props;
  return <Container status={value}>{value}</Container>;
};

export default Status;

Status.propTypes = {
  value: PropTypes.string
};
