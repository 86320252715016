import { Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

const CustomTab = ({ currentTab, onChange, tabs }) => {
  return (
    <Tabs
      indicatorColor="primary"
      onChange={onChange}
      scrollButtons="auto"
      sx={{ mt: 3 }}
      textColor="primary"
      value={currentTab}
      variant="scrollable">
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
};

export default CustomTab;

CustomTab.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};
