import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const Domaine = ({ value }) => {
  return (
    <Typography sx={{ fontWeight: '600', color: 'text.grey', textAlign: 'center' }}>
      {value}
    </Typography>
  );
};

export default Domaine;
Domaine.propTypes = {
  value: PropTypes.string
};
