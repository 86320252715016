import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const BackToButton = ({ label, path }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mb: 4 }}>
      <Button
        component="a"
        onClick={() => navigate(path)}
        startIcon={<ArrowBackIcon fontSize="small" />}
        sx={{ color: 'black' }}>
        {label}
      </Button>
    </Box>
  );
};

BackToButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default BackToButton;
