import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Circle = styled('div')(() => ({
  height: '3.5rem',
  width: '3.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  fontWeight: '500',
  margin: '0 auto',
  borderRadius: '50%',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#E0E0E0'
}));
const AssuranceLevel = (props) => {
  const { value } = props;
  return <Circle> {value}</Circle>;
};

export default AssuranceLevel;

AssuranceLevel.propTypes = {
  value: PropTypes.string
};
