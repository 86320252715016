import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const InfoWrapper = (props) => {
  const { values } = props;
  return (
    <Box>
      <Typography sx={{ fontWeight: '600' }}>{values[0].value}</Typography>
      <Typography sx={{ fontSize: '14px', color: 'text.lightGrey' }}>{values[1].value}</Typography>
    </Box>
  );
};

export default InfoWrapper;

InfoWrapper.propTypes = {
  values: PropTypes.array
};
