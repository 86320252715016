import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Modal, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 50,
  boxShadow: 24,
  p: 4
};

const ImageDocument = (props) => {
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const { back, front } = props;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showImage = (imageUrl) => {
    setImage(imageUrl);
    handleOpen();
  };

  return (
    <div>
      <Box>
        <Tooltip title="show front">
          <VisibilityIcon color="success" onClick={() => showImage(front)} />
        </Tooltip>
        {back && (
          <Tooltip title="show back">
            <VisibilityIcon color="error" onClick={() => showImage(back)} />
          </Tooltip>
        )}
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <img src={image} alt="Document" />
        </Box>
      </Modal>
    </div>
  );
};

export default ImageDocument;

ImageDocument.propTypes = {
  back: PropTypes.string,
  front: PropTypes.string
};
