import { BrowserRouter as Router } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './helpers/utils/Keycloak';
import { ThemeProvider } from '@mui/material/styles';
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context';
import { createTheme } from './theme';
import DashboardPage from './pages/Dashboard';
import AuthGuard from './helpers/auth/AuthGuard';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
const queryClient = new QueryClient();

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false
      }}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeProvider
                  theme={createTheme({
                    direction: settings.direction,
                    responsiveFontSizes: settings.responsiveFontSizes,
                    mode: settings.theme
                  })}>
                  <AuthGuard>
                    <DashboardPage />
                  </AuthGuard>
                  <ReactQueryDevtools initialIsOpen={true} />
                </ThemeProvider>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </QueryClientProvider>
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;
