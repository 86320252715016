import React, { useEffect } from 'react';
import { Box, Container, TableContainer, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import IdentityRow from '../../components/identities/IdentityRow';
import CustomTable from '../../components/shared/CustomTable';
import requestService from '../../helpers/services/request-service';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import Spinner from '../../components/shared/Spinner';
import IdentitySkeleton from './../../components/identities/IdentitySkeleton/index';

const IdentitiesContainer = () => {
  const headers = [
    '',
    'Client',
    'Date de création',
    "Niveau d'assurance",
    'Domaine',
    'Statut',
    'Nombre de Documents',
    ''
  ];
  let rows = [];
  const { ref, inView } = useInView();
  const { data, fetchNextPage, isFetching, isError, isLoading } = useInfiniteQuery(
    'identities',
    async ({ pageParam = 0 }) => {
      const res = await requestService.get(`/page/${pageParam}/size/4`, true);
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) =>
        firstPage.first ? undefined : firstPage.pageable.pageNumber + 1,
      getNextPageParam: (lastPage) =>
        lastPage?.last ? undefined : lastPage?.pageable?.pageNumber + 1
    }
  );
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  if (data) {
    data.pages.map((page, index) => {
      return page.content.map((identity) => {
        rows.push(<IdentityRow key={`${identity.identityKey}-${index}`} identity={identity} />);
      });
    });
  }
  if (isError) {
    return <Typography>An error has occured</Typography>;
  }
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
            px: 4
          }}>
          <Container
            maxWidth="xl"
            style={{
              borderRadius: '6px',
              border: '1px solid #DFE0EB'
            }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 }
              }}>
              <Typography variant="h5">Identities</Typography>
            </Toolbar>
            <TableContainer
              style={{ maxHeight: '70vh', minHeight: '30vh' }}
              sx={{
                '&::-webkit-scrollbar': {
                  width: 3
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'white'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#73777B',
                  borderRadius: 1
                }
              }}>
              <CustomTable rows={rows} headers={headers} />
              <div ref={ref} color="primary" style={{ margin: '10px', position: 'relative' }}>
                {isFetching ? (
                  <IdentitySkeleton />
                ) : (
                  <Typography
                    sx={{
                      fontSize: '.8rem',
                      fontWeight: '400'
                    }}>
                    Nothing more to load
                  </Typography>
                )}
              </div>
            </TableContainer>
          </Container>
        </Box>
      )}
    </>
  );
};

export default IdentitiesContainer;

IdentitiesContainer.propTypes = {
  theme: PropTypes.object
};
