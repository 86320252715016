import React from 'react';
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';
import { Home as HomeIcon } from '../../shared/Icons/home';
import { ChartBar as ChartBarIcon } from '../../shared/Icons/chart-bar';
import { UserCircle as UserCircleIcon } from '../../shared/Icons/user-circle';
import { SidebarSection } from './sidebar-section';
import { Logo } from '../../shared/Icons/logo';
import { Scrollbar } from '../../shared/ScrollBar';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Accueil',
        path: '/',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: 'Identities',
        path: '/identities',
        icon: <ChartBarIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/account',
        icon: <UserCircleIcon fontSize="small" />
      }
    ]
  }
];

const Sidebar = (props) => {
  const location = useLocation();
  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}>
          <div>
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <a>
                <Logo
                  sx={{
                    height: 42,
                    width: 42
                  }}
                />
              </a>
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            {sections.map((section) => (
              <SidebarSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...section}
              />
            ))}
          </Box>
        </Box>
      </Scrollbar>
    </>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
