import { Box, Container, Grid } from '@mui/material';
import StatisticsTotalIdentities from '../../components/analytics/StatisticsDocumentsStatus';
import StatisticsDocumentsStatus from '../../components/analytics/StatisticsTotalIdentities';

const HomeContainer = () => {
  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xl={12} xs={12}>
            <StatisticsDocumentsStatus />
          </Grid>
          <Grid item xl={8} xs={12}>
            <StatisticsTotalIdentities />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HomeContainer;
