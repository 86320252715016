import React from 'react';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { Snackbar } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Toast = ({ open, message, severity, setOpen }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert
        severity={severity}
        sx={{ width: '100%', fontSize: '1.5rem', display: 'flex', alignItems: 'center' }}>
        {message} {open}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

Toast.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  setOpen: PropTypes.func
};
