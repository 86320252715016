const dev = {
  url: {
    KEYCLOAK_BASE_URL: 'http://localhost:8080/auth'
  },
  realm: 'kyc-realm',
  clientId: 'kyc-console-front',
  API_BASE_URL: 'http://localhost:8081/kyc-service/identities'
};

const prod = {
  url: {
    KEYCLOAK_BASE_URL: 'https://oidc.campus.clusterdiali.me/auth'
  },
  realm: 'kyc-realm',
  clientId: 'kyc-console-front',
  API_BASE_URL: 'https://kyc-api.campus.clusterdiali.me/kyc-service/identities'
};

// eslint-disable-next-line no-undef
export const config = process.env.NODE_ENV === 'production' ? prod : dev;
