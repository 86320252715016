import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

const CustomTable = ({ headers, rows }) => {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell align="center" key={index}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>{row}</TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array
};
