import { CardMedia } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const DocumentImage = ({ path }) => {
  return (
    <CardMedia
      component="img"
      image={path}
      alt="CardMedia Image Example"
      height="180"
      sx={{
        borderRadius: '15px'
      }}
      fit="cover"
      title="CardMedia Image Example"
    />
  );
};

export default DocumentImage;

DocumentImage.propTypes = {
  path: PropTypes.string
};
