import React, { useState } from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { Scrollbar } from '../../shared/ScrollBar';
import DocumentRow from '../DocumentRow';
import CustomTable from '../../shared/CustomTable';
import PropTypes from 'prop-types';
import Toast from './../../shared/Toast/index';

const DocumentList = ({ documents, identityKey }) => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const setToast = (severity, message) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };
  const headers = [
    'ID',
    'Type',
    "Date d'envoie",
    'Numéro de document',
    'Pays',
    "Date d'expiration",
    'Score',
    'Status',
    'Images',
    'Actions'
  ];

  const rows = documents.map((document) => (
    <DocumentRow
      key={document.evidenceId}
      setToast={setToast}
      document={document}
      identityKey={identityKey}
    />
  ));

  return (
    <div>
      <Card>
        <CardHeader title="Documents :" />
        <Divider />
        <Scrollbar>
          <CustomTable headers={headers} rows={rows} />
        </Scrollbar>
      </Card>
      <Toast open={open} message={message} setOpen={setOpen} severity={severity} />
    </div>
  );
};

DocumentList.propTypes = {
  documents: PropTypes.array.isRequired,
  identityKey: PropTypes.string.isRequired
};

export default DocumentList;
