import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex'
};

const Spinner = () => {
  return (
    <Box sx={style}>
      <Typography sx={{ fontWeight: 700, marginRight: '1rem', fontSize: '1.5rem' }}>
        Loading
      </Typography>
      <CircularProgress color="success" />
    </Box>
  );
};

export default Spinner;
