import { Box, Button, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import DocumentList from '../../components/documents/DocumentsList';
import ClientDetails from '../../components/identities/ClientDetails';
import IdenityKey from '../../components/identities/IdentityKey';
import Tab from '../../components/identities/Tab';
import PortraitValidation from '../../components/portrait/PortraitValidation';
import BackToButton from '../../components/shared/BackToButton';
import Spinner from '../../components/shared/Spinner';
import requestService from '../../helpers/services/request-service';
import Toast from '../../components/shared/Toast';
import { ALERTS } from '../../helpers/constants/alerts';

const IdentityDetailsContainer = () => {
  const { id: identityKey } = useParams();
  const tabs = [
    {
      label: 'Client',
      value: 'client'
    },
    {
      label: 'documents',
      value: 'documents'
    },
    {
      label: 'Portrait',
      value: 'portrait'
    }
  ];
  const [currentTab, setCurrentTab] = useState('client');

  const {
    data: identity,
    isLoading,
    isError
  } = useQuery(`identity/${identityKey}`, async () => {
    const res = await requestService.get(`/${identityKey}/details`, true);
    return res.data;
  });

  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');

  const queryClient = useQueryClient();
  const setToast = (severity, message) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const { mutate } = useMutation(
    (key) => requestService.post(`/identity`, { identityKey: key }, true, 'text/plain'),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries(`identity/${identityKey}`);
        setToast('success', ALERTS.IDENTITY_FINALISE_SUCCESS);
      },
      onError: () => {
        setToast('error', ALERTS.IDENTITY_FINALISE_ERROR);
      }
    }
  );

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isAbleToBeFinilized = (identity) => {
    const isDocumentsValidated = identity.identityDocument.some(
      (document) => document.status === 'PROCESSING'
    );
    const isPortraitValidated = identity.portrait.status === 'PROCESSING';
    return isPortraitValidated || isDocumentsValidated;
  };
  if (isError) {
    return <p>An error has occured</p>;
  }
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 5
          }}>
          <Box sx={{ mx: 10 }}>
            <div>
              <BackToButton label="Identities" path="/identities" />
              <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
                <IdenityKey
                  displayPortrait={currentTab === 'portrait'}
                  client={{
                    name: `${identity.customerInfo?.firstName} ${identity.customerInfo?.lastName}`,
                    portrait: '/avatars/avatar-anika_visser.png'
                  }}
                  identityKey={identity.identityKey}
                />
                <Button
                  onClick={() => mutate(identityKey)}
                  variant="contained"
                  disabled={isAbleToBeFinilized(identity)}
                  sx={{
                    width: '200px',
                    fontWeight: 'bold',
                    padding: '10px',
                    backgroundColor: (theme) => theme.palette.statusBackground.finished
                  }}>
                  Finaliser identité
                </Button>
              </Box>
              <Tab tabs={tabs} currentTab={currentTab} onChange={handleTabsChange} />
            </div>
            <Divider />
            <Box sx={{ mt: 3 }}>
              {currentTab === 'client' && <ClientDetails client={identity.customerInfo} />}
              {currentTab === 'documents' && (
                <DocumentList documents={identity.identityDocument} identityKey={identityKey} />
              )}
              {currentTab === 'portrait' && (
                <PortraitValidation portrait={identity.portrait} identityKey={identityKey} />
              )}
            </Box>
          </Box>
          <Toast open={open} message={message} setOpen={setOpen} severity={severity} />
        </Box>
      )}
    </>
  );
};

export default IdentityDetailsContainer;
