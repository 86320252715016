import { styled } from '@mui/material/styles';

export const Logo = styled(() => {
  return (
    <svg width="85" height="82" viewBox="0 0 85 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M82.9395 2.23644C85.2576 4.4728 85.2576 8.44855 82.9395 10.6849C80.6213 12.9213 76.5001 12.9213 74.1819 10.6849C71.8637 8.44855 71.8637 4.4728 74.1819 2.23644C76.5001 -0.248409 80.6213 -0.248409 82.9395 2.23644Z"
        fill="white"
      />
      <path
        d="M57.697 26.5879C60.2727 29.0728 60.2727 33.0485 57.697 35.2849C55.1212 37.7697 51 37.7697 48.6818 35.2849C46.1061 32.8 46.1061 28.8243 48.6818 26.5879C51 24.3516 55.1212 24.3516 57.697 26.5879Z"
        fill="white"
      />
      <path
        d="M42.5 64.8546C29.1061 64.8546 18.0303 54.1697 18.0303 41.2485C18.0303 28.3273 29.1061 17.6425 42.5 17.6425C55.8939 17.6425 66.9697 28.3273 66.9697 41.2485C66.9697 54.1697 55.8939 64.8546 42.5 64.8546ZM42.5 0.248535C19.3182 0.248535 0.257568 18.6364 0.257568 41.0001C0.257568 63.6122 19.3182 81.7516 42.5 81.7516C65.6818 81.7516 84.7424 63.3637 84.7424 41.0001C84.7424 18.6364 65.6818 0.248535 42.5 0.248535Z"
        fill="white"
      />
    </svg>
  );
})``;
