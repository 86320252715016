import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Modal, TextField, Toolbar, Typography } from '@mui/material';
import DocumentImage from './../DocumentImage/index';
import DocumentDetailsList from './../DocumentDetailsList/index';
import PropTypes from 'prop-types';
import requestService from '../../../helpers/services/request-service';
import { useMutation, useQueryClient } from 'react-query';
import { STATUS } from '../../../helpers/constants/Status';
import { PLACEHOLDER } from '../../../helpers/constants/placeholder';
import { ALERTS } from '../../../helpers/constants/alerts';

const DocumentDetailsModal = ({
  motif,
  open,
  setMotif,
  handleClose,
  document,
  identityKey,
  setToast
}) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (documentStatus) =>
      requestService.patch(
        `/${identityKey}/documents/${document.evidenceId}`,
        documentStatus,
        true
      ),
    {
      onMutate: () => {
        handleClose();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(`identity/${identityKey}`);
        setToast('success', ALERTS.DOCUMENT_STATUS_SUCCESS);
        setMotif('');
      },
      onError: () => {
        setToast('error', ALERTS.DOCUMENT_STATUS_ERROR);
        setMotif('');
      }
    }
  );
  useEffect(() => {
    if (document.motif && open) {
      setMotif(document.motif);
    } else {
      setMotif('');
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Container
        maxWidth="md"
        style={{
          borderRadius: '6px',
          border: '1px solid #DFE0EB',
          marginTop: '20px',
          background: '#fff',
          padding: '20px'
        }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
          }}>
          <Typography variant="h5">Document Details</Typography>
        </Toolbar>

        <Grid container spacing={5} columns={{ xs: 4, md: 12 }}>
          <Grid item xs={6}>
            <DocumentImage path="https://upload.wikimedia.org/wikipedia/commons/0/06/Slovenian_ID_Card_2022_-_Front.jpg" />
          </Grid>
          <Grid item xs={6}>
            <DocumentImage path="https://upload.wikimedia.org/wikipedia/commons/3/38/Bulgarian_identity_card_back.png" />
          </Grid>
        </Grid>

        <DocumentDetailsList document={document} />

        <Box>
          <TextField
            placeholder={PLACEHOLDER.DOCUMENT_MOTIF}
            multiline
            value={motif}
            onChange={(e) => setMotif(e.target.value)}
            rows={2}
            sx={{ width: '100%', marginTop: '20px' }}
            maxRows={4}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
            flexWrap: 'wrap'
          }}>
          <Button
            role="invalidate"
            disabled={motif === ''}
            sx={{
              width: '100px',
              backgroundColor: (theme) =>
                motif === '' ? '#F5F5F5' : theme.palette.stausButton.red,
              color: '#fff'
            }}
            onClick={() => mutate({ status: STATUS.INVALID, motif: motif })}>
            Invalider
          </Button>
          <Button
            role="validate"
            sx={{
              width: '100px',
              backgroundColor: (theme) => theme.palette.stausButton.green,
              marginLeft: '10px',
              color: '#fff'
            }}
            onClick={() => mutate({ status: STATUS.VALID })}>
            Valider
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};

export default DocumentDetailsModal;

DocumentDetailsModal.propTypes = {
  motif: PropTypes.string,
  open: PropTypes.bool,
  setMotif: PropTypes.func,
  handleClose: PropTypes.func,
  setToast: PropTypes.func,
  document: PropTypes.object,
  identityKey: PropTypes.string
};
